import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useFirebaseProvider } from "../providers/Firebase/firebase";
import { ListItem } from "@material-ui/core";
import FormPedido from "../components/forms/formPedido";
import NoListCard from "./noDataCards/NoListaCard";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IconButton from "@material-ui/core/IconButton";
const drawerWidth = 400;
const StyledTableCell = withStyles((theme) => {
  return {
    head: {
      backgroundColor: theme.palette.tertiary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  };
})(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  totalServicio: {
    textAlign: "center",
    fontSize: "small",
    whiteSpace: "break-spaces",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: "90%",
    maxWidth: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: "90%",
    maxWidth: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    //padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    minHeight: "48px !important",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  total: {
    textAlign: "center",
    display: "block",
  },
}));

const ListaCompra = () => {
  const classes = useStyles();
  const { drawer, lista, setDrawer, total, costoServicio } = useFirebaseProvider();
  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton
          onClick={() => {
            setDrawer(false);
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      {lista.length < 1 ? (
        <NoListCard />
      ) : (
        <React.Fragment>
          <Table size="small" aria-label="listaMercado">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Artículo</StyledTableCell>
                <StyledTableCell align="center">Cantidad</StyledTableCell>
                <StyledTableCell align="center">Precio</StyledTableCell>
                <StyledTableCell align="right">Total </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {lista.map((item, index) => (
                <StyledTableRow key={item.id}>
                  <StyledTableCell align="center">
                    {item.nombre}{" "}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.cantidad}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.precio}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    Q.{item.total}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <Divider />
          <ListItem className={classes.total}>Total Q.{total}</ListItem>
          <div className={classes.totalServicio}>
            {total > 75
              ? `+Q${costoServicio} de desinfeccion = Q${
                  total + costoServicio
                }\nadquisicion y servicio a domicilio GRATIS`
              : `Q${costoServicio}  de adquisición, desinfeccion y servicio a
            domicilio Q${total + costoServicio}`}
          </div>
        </React.Fragment>
      )}
      <FormPedido />
    </Drawer>
  );
};
export default ListaCompra;
