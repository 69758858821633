import React/*,{useEffect, useState} */from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import BaseCard from "../components/baseCard/baseCard"
import Grid from '@material-ui/core/Grid';
//import {productoInterface} from "../interfaces/itemsInterface"
import  HeaderGeneral  from "../components/header";

import  { useFirebaseProvider } from "../providers/Firebase/firebase"

const Tab2: React.FC = () => {
  const {frutas:items}: { frutas:Array<any> }  = useFirebaseProvider();
 /* const [items, setItems] = useState<productoInterface[]>([])  
 // const {total}:{  total:number }  = useFirebaseProvider();
  useEffect(() => {
    //let db:firebase.firestore.Firestore=db;
      const getProductos=async()=> {
       const data= await db.collection("items").where("categoria","==","frutas").get();
       let itemColection=data.docs.map(item=> ({...item.data(), id:item.id}) )  
       setItems(itemColection);
      }
      getProductos();   
  }, [db])*/

  return (
      
      <IonPage>
        <HeaderGeneral/>
        <IonContent >
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">Verduras</IonTitle>
            </IonToolbar>
          </IonHeader>

            <Grid style={{padding: 20,}} container spacing={4}> 
              {items.map((item,index)=>  
                  (       
                    <BaseCard  key={index}  item={item}/>
                  )
              )}
           </Grid>
          {/* <ExploreContainer name="Tab 1 page" /> */}
        </IonContent>
      </IonPage>
  );
};
export default Tab2;
