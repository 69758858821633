import React, {useState, useEffect } from 'react';
import {  Grid, Typography } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import InfoWrapper from "../InfoWrapper/InfoWrapper";
import StyledSlider from "../slider/StyledSlider";
import  { useFirebaseProvider } from "../../providers/Firebase/firebase";

const useStyles = makeStyles((theme) => ({
      root: {
        flexGrow: 1,
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height:156,
      },
      contenedor:{
        height: '100%',
        textAlign: 'left',
      },
      sliderContainer:{
        padding: '0 0 0 10px !important',
        marginTop: -10,
      }
    }
  ));

const BaseCard: React.FC<any>=(props:any)=>{

const {agregarALista,quitarDeLista,reset}: {  agregarALista:Function ;quitarDeLista:Function;reset:boolean }  = useFirebaseProvider();
const [totalItem, settotalItem] = useState(0)
const [cantidad, setCantidad] = useState(0)
const [item] = useState(props.item)

const handleSliderCommit = (value:any)=>{
  setCantidad(value)
  if (value>0){
    const total=value * item.precio;
    settotalItem(total)
    agregarALista({...item, total,cantidad:value})
    return
  }  
  settotalItem(0)
  quitarDeLista(item.id);
}
useEffect(() => {
  if(reset){
  setCantidad(0)
  }
}, [reset])

const classes = useStyles();
       return (
         <Grid item xs={props.xs || 12} sm={props.sm || 6} md={props.md || 4} lg={props.lg || 3} xl={props.xl || 2}>
            <Paper elevation={4} className={classes.paper}>
              <Grid className={classes.contenedor} container spacing={2}>
                <InfoWrapper item={item}/>
                <Grid className={classes.sliderContainer} item xs={8}>
                  <StyledSlider value={cantidad} max={item.max} onCommit={handleSliderCommit} />                  
                </Grid>
                <Grid style={{padding:0}} item xs={4}>
                  <Typography align="center">
                    Total Q.{totalItem}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
        </Grid>
       );    
    }

export default BaseCard;