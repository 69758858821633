import React from 'react';
import {TextField } from '@material-ui/core';
import {withFormsy} from 'formsy-react';

import InputLabel from '@material-ui/core/InputLabel';

function TextFieldFormsy(props)
{
  //const importedProps = props
  const {size,helperText,autoComplete,autoFocus,children,className,defaultValue,disabled,FormHelperTextProps,fullWidth,id,InputLabelProps,inputProps,InputProps,inputRef,label,multiline,
    name,onBlur,onChange,onFocus,placeholder,required,rows,rowsMax,select,SelectProps,type,variant}=props
  const importedProps= { size, helperText,autoComplete,autoFocus,children,className,defaultValue,disabled,FormHelperTextProps,fullWidth,id,InputLabelProps,inputProps,InputProps,inputRef,
    label,multiline,name,onBlur,onChange,onFocus,placeholder,required,rows,rowsMax,select,SelectProps,type,variant}
    const errorMessage = props.errorMessage;
    const value = props.value || '';

    function changeValue(event)
    {
        props.setValue(event.currentTarget.value);
        if ( props.onChange )
        {
            props.onChange(event);
        }
    }
    return (
      <React.Fragment>        
        {Boolean(props.textoTop)?<InputLabel style={{fontSize:'small'}} htmlFor="input-with-icon-adornment">{props.textoTop}</InputLabel>:null}
          <TextField
           style={{margin:8}}
            {...importedProps}
            onChange={changeValue}
            value={value}
            label={props.label} 
            variant="outlined"
            error={Boolean(errorMessage)||(props.touched&&!Boolean(value)&&required)}       

            helperText={errorMessage||helperText}
          />
      </React.Fragment>
    );
}

export default React.memo(withFormsy(TextFieldFormsy));
