//import Firebase from './FirebaseInit';
import React, { useMemo /*,useEffect*/, useState, useEffect } from "react";
import firebase from "firebase";
import {
  compraMinima,
  costoServicio as costoServicioConstant,
  costoDesinfeccion,
} from "../../utils/constants";
const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

const FirebaseContext = React.createContext();
export function getTimeStamp() {
  return firebase.firestore.Timestamp.fromDate(new Date());
}

firebase.initializeApp(config);
export function FirebaseProvider(props) {
  const [items, setItems] = useState([]);

  const [verduras, setVerduras] = useState([]);
  const [frutas, setFrutas] = useState([]);
  const [carnes, setCarnes] = useState([]);
  const [total, setTotal] = useState(0);
  const [lista, setLista] = useState([]);
  const [drawer, setDrawer] = useState(false);
  const [db, setdb] = useState();
  const [reset, setReset] = useState(false);
  const [costoServicio, setcostoServicio] = useState(costoServicioConstant);
  //instancia de db
  useEffect(() => {
    console.log("reinstancia db");
    setdb(firebase.firestore());
  }, []);

  useEffect(() => {
    const getProductos = async () => {
      let data = await db.collection("items").get();
      let itemColection = data.docs.map((item) => ({
        ...item.data(),
        id: item.id,
      }));
      setVerduras(
        itemColection.filter((item) => item.categoria === "verduras")
      );
      setFrutas(itemColection.filter((item) => item.categoria === "frutas"));
      setCarnes(itemColection.filter((item) => item.categoria === "carnes"));
      setItems(itemColection);
    };
    if (db) getProductos();
  }, [db]);

  //calculo del total
  const calcularTotal = (nuevaLista) => {
    let total = nuevaLista.reduce((total, item) => {
      return total + item.total;
    }, 0);
    setTotal(total);
    setcostoServicio(
      total > compraMinima ? costoDesinfeccion : costoServicioConstant
    );
  };

  //exportacion de variables mediante useMemo para desplegar cambios innecesarios
  const value = useMemo(() => {
    const agregarALista = (item) => {
      const index = lista.findIndex((itemLista) => itemLista.id === item.id);
      if (index === -1) {
        const nuevaLista = [...lista, item];
        setLista([...lista, item]);
        calcularTotal(nuevaLista);
        return;
      }
      let nuevaLista = [...lista];
      nuevaLista[index] = item;
      setLista(nuevaLista);
      calcularTotal(nuevaLista);
    };
    const quitarDeLista = (id) => {
      const index = lista.findIndex((item) => item.id === id);
      let nuevalista = [...lista];
      nuevalista.splice(index, 1);
      setLista(nuevalista);
      calcularTotal(nuevalista);
    };
    const limpiar = () => {
      setLista([]);
      setTotal(0);
      setDrawer(false);
      setReset(true);
      setReset(false);
      setcostoServicio(costoServicioConstant);
    };
    return {
      db,
      total,
      lista,
      setTotal,
      setLista,
      agregarALista,
      quitarDeLista,
      drawer,
      setDrawer,
      verduras,
      frutas,
      carnes,
      items,
      limpiar,
      reset,
      costoServicio,
    };
  }, [
    db,
    total,
    lista,
    drawer,
    verduras,
    frutas,
    carnes,
    items,
    reset,
    costoServicio,
  ]);
  return <FirebaseContext.Provider value={value} {...props} />;
}

export function useFirebaseProvider() {
  const context = React.useContext(FirebaseContext);
  return context;
}
