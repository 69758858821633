import React/*,{useEffect, useState} */from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import BaseCard from "../components/baseCard/baseCard"
import Grid from '@material-ui/core/Grid';
//import {productoInterface} from "../interfaces/itemsInterface"

import  { useFirebaseProvider } from "../providers/Firebase/firebase";
import  HeaderGeneral  from "../components/header";
//import ExploreContainer from '../components/ExploreContainer';
//import './Tab1.css';
//import firebase from 'firebase';

const Tab1: React.FC = () => {
  const {verduras:items}: { verduras:Array<any> }  = useFirebaseProvider();


  return (
      
      <IonPage>
        <HeaderGeneral/>
        <IonContent >
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">Verduras</IonTitle>
            </IonToolbar>
          </IonHeader>
            <Grid style={{padding: 20,}} container spacing={4}> 
              {items.map((item,index)=>  
                  (       
                    <BaseCard  key={index}  item={item}/>
                  )
              )}
           </Grid>
          {/* <ExploreContainer name="Tab 1 page" /> */}
        </IonContent>
      </IonPage>
  );
};

export default Tab1;
