import React, {  } from 'react'
import { IonCard} from '@ionic/react';

 const NoListCard= ()=> {
    return (
        <IonCard color="tertiary" style={{margin:20, padding:20,textAlign:'center'}}>
                Para seleccionar artículos arrastre los selectores a la cantidad deseada
        </IonCard>
    );
}
export default NoListCard