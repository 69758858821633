import React, { } from 'react';
import {  Grid, Typography } from "@material-ui/core";
import {  makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    capitalize:{
      textTransform:'capitalize',
    },
    contenedor:{
       // height:'200px',
       padding: 4,
    },
    noWrap:{
       'white-space': 'nowrap',
        'overflow': 'hidden',
        'text-overflow': 'ellipsis',
        
    },
    etiqueta:{
        'font-size': '60%',
        marginTop:8,
        float: 'left',
    },
    _datos:{
        'line-height': 1.5,
        'white-space': 'pre',
        overflow: 'hidden',
       padding: 4,
    },
    datosCapitalize:{
        //'line-height': 1.5,
        'white-space': 'pre',
        overflow: 'hidden',
        padding: 4,
        textTransform:'capitalize',
        'text-overflow': 'clip',
    },
    nowrap:{
        'text-overflow': 'clip',
        'white-space': 'nowrap',
    }

  }));
let urlBaseImg=process.env.PUBLIC_URL+'/img/';

/*const coloresIndicador={
    1:'green',
    2:'yellow',
    3:'red',
}*/

const InfoWrapper: React.FC<any>=(props:any)=>{
const item=props.item;
const classes = useStyles();
       return (
            <Grid container className={classes.contenedor}>
                <Grid container style={{maxHeight:'64px'}}>                    
                    <Grid item xs={9}>
                        <Typography className={classes.datosCapitalize } variant="h4">
                            {item.nombre} 
                        </Typography>
                        <Typography  className={classes.noWrap } variant="body1">
                            {item.descripcion}
                        </Typography>              
                    </Grid>
                    <Grid item xs={3}>
                        <img style={{maxHeight:"72px"}} height="auto" width="auto" src={urlBaseImg+item.imagen} alt={item.imagen}></img>
                    </Grid>            
                </Grid>      

                <Grid item xs={6}>
                    <Typography className={classes.datosCapitalize  } align="center" variant="h6">
                        <span className={classes.etiqueta}>por:</span> {item.unidad}
                    </Typography>
                </Grid>
                <Grid className={classes.nowrap} item xs={3} >
                        {//<span style={{width:10,height:10,background:coloresIndicador[1], display: 'block',position: 'absolute',borderRadius: 10,marginTop: 15,marginLeft: -8}}></span>
                        }
                    <Typography style={{padding:4}} align="center" variant="h6">
                        <span className={classes.etiqueta}>Precio:   Q.</span> {item.precio}
                    </Typography>
                </Grid>
            </Grid>
       );    
    }

export default InfoWrapper;