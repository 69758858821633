import React, {  } from 'react';
import { withStyles} from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';


const PrettoSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
    WebkitTextFillColor:'white',
    transform: 'scale(.95) translateY(60px) !important',
    '& span':{
      'border-radius': '50% 0 50% 50% !important',
      },

  },

  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
    width:'101%',
  },
  mark:{
    height: 8, 
  },
  
})(Slider);

const StyledSlider: React.FC<any>=(props:any)=>{
    const handleChangue = (e:any,value:any)=>{
        props.onCommit(value);
    }

    return (
              <PrettoSlider 
                  value={props.value}               
                  step={props.steps||1}
                  marks
                  min={props.min||0}
                  max={props.max||10}
                  valueLabelDisplay="on" aria-label="pretto slider" defaultValue={0} 
                  onChangeCommitted={handleChangue}
                />  )         
            }

export default StyledSlider;