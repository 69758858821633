import React, {} from 'react';
import  { useFirebaseProvider } from "../providers/Firebase/firebase";
import { IonButton, IonIcon } from '@ionic/react';
import { listOutline } from 'ionicons/icons';

import {  IonHeader,  IonTitle, IonToolbar } from '@ionic/react';

const HeaderGeneral: React.FC<any>=(props:any)=>{
    const {total,drawer,setDrawer}:{  total:number ; drawer:boolean ; setDrawer:Function }  = useFirebaseProvider();

    const changueStateDrawer=()=>{
        setDrawer(!drawer);
    }
        return (
            <IonHeader>
            <IonToolbar color="secondary">
                <IonTitle style={{color:'white',/*fontSize:'x-large',*/ "textAlign": "center"}}>
                    Total Q.{total}
                </IonTitle>
                <IonButton onClick={changueStateDrawer} style={{'marginRight':'5px'}} color="primary" slot="end">
                    <IonIcon slot="start" icon={listOutline} />
                    Ver mi lista
                </IonButton>
            </IonToolbar>
            </IonHeader>
        );    
    }

export default HeaderGeneral;