import React , { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { basket,} from 'ionicons/icons';
import Tab1 from './pages/Tab1';
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';
//import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { FirebaseProvider } from './providers/Firebase/firebase';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import ListaCompra from './components/ListaCompra'

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Raleway',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
  },
  palette:{
    primary:{
      main:"#356211"
    },
    secondary:{
      main:"#338353"
    },
   /* success:{
      main:"#338353"
    },*/
    tertiary:{
      main:"#AA471F"
    }
 }
});



class App extends Component{ 
  //const theme = useTheme();
  render(){
    return(
    <IonApp >
        <FirebaseProvider>
      <ThemeProvider theme={theme}>
          <IonReactRouter>
            <IonTabs>
              <IonRouterOutlet>
                <Route path="/verduras" component={Tab1} exact={true} />
                <Route path="/frutas" component={Tab2} exact={true} />
                <Route path="/carnes" component={Tab3} />
                <Route path="/" render={() => <Redirect to="/frutas" />} exact={true} />
              </IonRouterOutlet>
              <IonTabBar slot="bottom" color="success">
                <IonTabButton tab="verduras" href="/verduras">
                  <IonIcon icon={basket} />
                  <IonLabel>Verduras</IonLabel>
                </IonTabButton>
                <IonTabButton tab="frutas" href="/frutas">
                  <IonIcon icon={basket} />
                  <IonLabel>Frutas</IonLabel>
                </IonTabButton>
                <IonTabButton tab="carnes" href="/carnes">
                  <IonIcon icon={basket} />
                  <IonLabel>Carnes</IonLabel>
                </IonTabButton>
              </IonTabBar>
            </IonTabs>
            <ListaCompra />
          </IonReactRouter>
      </ThemeProvider>
        </FirebaseProvider>
    </IonApp>
  )}
}

export default App