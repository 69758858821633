import React/*,{useEffect, useState}*/ from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import BaseCard from "../components/baseCard/baseCard";
import Grid from '@material-ui/core/Grid';
//import {productoInterface} from "../interfaces/itemsInterface";

import  HeaderGeneral  from "../components/header";
import  { useFirebaseProvider } from "../providers/Firebase/firebase";
//import './Tab3.css';

const Tab3: React.FC = () => {
  const {carnes:items}: { carnes:Array<any> }  = useFirebaseProvider();

  return (      
      <IonPage>
        <HeaderGeneral/>
        <IonContent >
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">Carnes</IonTitle>
            </IonToolbar>
          </IonHeader>
            <Grid style={{padding: 20,}} container spacing={4}> 
              {items.map((item,index)=>  
                  (       
                    <BaseCard  key={index}  item={item}/>
                  )
              )}
           </Grid>
        </IonContent>
      </IonPage>
  )
};

export default Tab3;