import React, { useState, /*useRef*/ } from "react";
import Formsy from "formsy-react";
import TextField from "./campos/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import { IonButton, IonIcon } from "@ionic/react";

import { send } from "ionicons/icons";
import { useFirebaseProvider, getTimeStamp } from "../../providers/Firebase/firebase";


import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  }));
const mensajes={
    exito:["¡Pedido Realizado!","En unos minutos nos comunicaremos con tigo, muchas gracias por tu confianza!."],
    errorPedidoExistente:["Tenemos un Problema :C", "ya tenemos un pedido pendiente con ese número, si quieres cambiar algo comunicalo atravez de Whatsapp (56222624) o Facebook"],
    error:["Tenemos un Problema :C", "si el problema persiste porfavor comunicalo atravez de Whatsapp (56222624) o Facebook I-verdura xela"]
}
const FormPedido = (props) => {

    const { lista,/* setLista,*/total, db,limpiar,costoServicio}  = useFirebaseProvider();
    const [guardando, setguardando] = useState(false)
    const [touched, settouched] = useState(false);
    const [mensajeOpen, setmensajeOpen] = useState(false)
    const [mensajesLabel, setMensajes] = useState(mensajes.exito);

    const procesarPedido=async(form)=>{
        setguardando(true);
        try{
            let numero =await db.collection("pedidos").where("tel", "==",form.tel ).where("pendiente","==",true).get();
           if(!numero.empty){
                setMensajes(mensajes.errorPedidoExistente);
                setmensajeOpen(true);
                setguardando(false);
                return
            }
            const costo =lista.reduce((sum,val)=>{     
                return sum+(val.costo*val.cantidad)},0);
            let data={
                tel:form.tel,
                cliente:{nombre:form.nombre, dir:form.dir,tel:form.tel},
                lista:lista,
                otroProducto:form.otros||"",
                total:total,
                costo:costo,
                pendiente:true,
                totalServicio:total+costoServicio,
                fecha: getTimeStamp(),
            }
            await db.collection("pedidos").add(data);
            await db.collection("ventas").add({items:lista, total:total,costo:costo});
            setMensajes(mensajes.exito);
            setmensajeOpen(true);
            limpiar();

        }catch(err){
           // console.log('err: ', err);
            setMensajes(mensajes.error);
            setmensajeOpen(true);
        }
        setguardando(false)
}
  
  const submit = (value) => {
  if (lista.length<1)
    return
  procesarPedido(value)
  };
  const handleInvalid = (e) => {
    settouched(true);
  };
  const classes=useStyles()



  return (
    <Paper className={classes.paper} elevation={5}>
        ¡Ingresa tus datos, confirma y en minutos nos comunicaremos para coordinar la entrega de tu mercado! 
      <Formsy
        //ref={formRef}
        onValidSubmit={submit}
        onInvalidSubmit={handleInvalid}
        noValidate
      >
        <Grid>
          <TextField
            name="nombre"//value={form.nombre}
            touched={touched}
            label={"Nombre"}
            required
            validations={{ minLength: 4 }}
            validationErrors={{ minLength: "Ingrese su nombre" }}
            size="small"
          />
          <TextField
            name="tel"
            touched={touched}
            label={"Teléfono"}
            required
            validations={{ isNumeric: true, isLength: 8 }}
            validationErrors={{
              isNumeric: "Unicamente números",
              isLength: "Ingrese un teléfono válido",
            }}
            size="small"
          />
        <TextField
            name="dir"
            touched={touched}
            multiline
            rowsMax="6"
            label={"Dirección"}
            required
            validations={{minLength: 10 }}
            validationErrors={{minLength: "Especifica más tu dirección"}}
            size="small"
          />
        <TextField
            name="otros"
            touched={touched}
            label={"Descripción"}
            textoTop="Si no encuentras lo que necesitas escríbelo"
            />
          <IonButton
            //onClick={submit} 
            disabled={guardando}
            type="submit"
            color="success"
            className={classes.button}
          >
            Confirmar mi lista
            <IonIcon slot="end" icon={send} />
          </IonButton>
        </Grid>
      </Formsy>
      <Dialog
        open={mensajeOpen}
        onClose={()=>{setmensajeOpen(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{mensajesLabel[0]}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
             {mensajesLabel[1]}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{setmensajeOpen(false)}} color="primary" autoFocus>
             Aceptar
          </Button>
        </DialogActions>
      </Dialog>

    </Paper>
  );
};

export default FormPedido;
